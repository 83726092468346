.notfound {
  font-size: 64px;
  font-weight: bold;
  text-align: center; }
.notfound-extended {
  font-size: 14px;
  text-align: center; }
.messages-wrapper {
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  //min-height: calc(100% - 55px - 55px) !important
  min-height: 50% !important;
  overflow-y: scroll;
  overflow-x: hidden; }
#modal-forward {
  .group-create {
    min-width: 100%;
    border: none!important;
    .forward-content {
      max-height: 200px;
      border: 1px solid #ced4da;
      border-radius: 5px;
      background-color: #e9ecef;
      padding: 10px;
      overflow: hidden;

      .forward_message {
        border-bottom: 1px solid;
        padding-bottom: 5px;
        min-height: 35px;
        svg {
          float: left;
          margin-right: 5px; } }
      #forward_input {
        background-color: transparent!important;
        border-color: transparent!important; }
      #forward_input:focus {
        background-color: transparent!important;
        border-color: transparent!important;
        box-shadow: unset; } }
    .forward-list {
      .search-list {
        margin-top: 10px;
        max-height: 50vh;
        overflow: scroll;
        overflow-x: hidden;
        .img {
          width: 25px;
          height: 25px;
          border-radius: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #666;
          color: #f7f7f8;
          font-size: 12px;
          float: left;
          margin-right: 5px; }
        li {
          border-top: none;
          border-left: none;
          border-right: none;
          div {
            width: 100%;
            .ms-2 {
              width: 100%; } } }
        li:hover {
          background-color: #f2f7f7; } } }
    .forward-hr {
      margin: 10px 0px;
      border-bottom: 1px solid #dee2e6; } }
  .modal-footer {
    padding: 0px;
    border: 0px;
    margin: 0 auto; }
  .forward-btn-send {
    line-height: 0.8 !important;
    border-radius: 20px;
    font-size: 12px;
    width: 85px; }
  .is_sent {
    width: 100px;
    background-color: transparent;
    color: #0d6efd; } }
.hide {
  display: none; }

