.pin_message p {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 13px;
}

.img_pin_message {
  display: inline-block;
  width: 4%;
  vertical-align: top;
}

.img_pin_message svg {
  width: 100px;
}

.block_pin_message {
  max-height: 90px;
  width: 90%;
  display: inline-block;
  overflow: hidden;
}
.pin_message {
  background: #fff;
  padding: 10px 10px;
  width: 100%;
  position: absolute;
  top: 102%;
  z-index: 999;
}

.action_pin_message {
  height: 100%;
  padding-left: 30px;
}
.content-reply {
  height: 25px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
