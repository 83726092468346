.answer_message_block span {
  display: block;
  font-size: 13px;
}

.comma_message{
  width: 21px;
}

.answer_message_block {
  background: #fff;
  padding: 15px 30px;
}

.block-content-message {
  background: #c8deffc2;
  padding: 12px;
  border-radius: 10px;
  margin-bottom: 10px;

}

.block-content-message-child {
  padding-left: 13px;
  border-left: 3px solid #fff;
}

.block-content-message-child div{
  width: 100%;
  max-width: 900px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.close_comma {
  position: absolute;
  right: 0;
  top: 0;
  border: 1px solid transparent;
  padding: 2px 6px;
  border-radius: 50%;
}

.close_comma:hover {
  background: #fff;
  cursor: pointer;
}
.message-input {
  width: 100% !important;
}
.message-input--multiLine{
  max-height: 115px;
  height: 115px!important;
}
.message-input__input {
  border: none!important;
  height: 115px!important;
}
.message-input__control{
  min-height: 115px!important;
}
.message-input__highlighter{
  height: 115px!important;
}
.message-input__suggestions {
  min-width: 100px!important;
}
.message-input__suggestions__list {
  max-height: 300px!important;
  min-width: 300px!important;
  background: #ffffff;
  border-radius: 10px;
  bottom: 40px!important;
}
.message-input__suggestions__item {
  border: none;
}
.message-input__suggestions__item .img {
  width: 25px;
  height: 25px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #666;
  color: #f7f7f8;
  font-size: 12px;
  float: left;
  margin-right: 5px;
}
.bottom-bar-tool {
  height: 36px;
  background-color: #ffffff;
  padding: 5px 0px;
  display: flex;
  float: left;
  width: 90%;
}
.bottom-bar-tool-extra {
  height: 36px;
  background-color: #ffffff;
  float: right;
  width: 10%;
}
.bottom-bar-tool .button {
  margin: 0 13px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.bottom-bar-tool-extra .button
{
  width: 36px;
  margin: -2px 13px;
  font-size: 24px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  float: right;
}
.bottom-basic .bottom-bar-conversation {
  max-height: 40px!important;
}
.bottom-basic .message-input--multiLine {
  height: 40px!important;
  max-height: 40px!important;
}
.bottom-basic .message-input__control {
  height: 40px!important;
  max-height: 40px!important;
}
.bottom-basic .message-input__input {
  height: 40px!important;
  max-height: 40px!important;
}
.bottom-basic .message-input__highlighter {
  height: 40px!important;
  max-height: 40px!important;
}

