.messages-wrapper {
  .message.attach-previous {
    padding-top: 0; }
  .message.attach-next {
    padding-bottom: 3px; }
  .name_user_left {
    padding: 8px 16px; }
  .name_user_right {
    padding: 8px 16px;
    text-align: right; }
  .spacer {
    width: 60px;
    height: 60px; }
  .message {
    > .picture {
      margin-bottom: -20px;
      cursor: pointer; }
    > .spacer {
      margin-bottom: -20px; } }
  .message.left {
    a {
      color: #0d6efd !important; } }
  .chat .message .content .bubble.attach-previous.left {
    border-top-left-radius: 0; }
  .chat .message .content .bubble.attach-previous.right {
    border-top-right-radius: 0; }
  .chat .message .content .bubble.attach-next.left {
    border-bottom-left-radius: 0; }
  .chat .message .content .bubble.attach-next.right {
    border-bottom-right-radius: 0; }
  .bubble.right a {
 }    //color: #252423
  .bubble.left a {
    color: #252423; }

  .content-download {
    display: flex;
    align-items: center; }
  .content-name {
    font-weight: bold; }
  .content-size {
    font-size: 12px; }
  .content-icon {
    min-width: 27px;
    width: 27px;
    font-size: 18px;
    margin-left: 10px; } }
