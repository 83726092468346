.nav-bar {
  width: calc(100% - 18px);
  padding: 0 9px;
  height: 50px;
  border-bottom: 1px solid #dadce0;
  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //width: 25%
    width: 33%;
    position: relative;
    .nav-noti {
      font-size: 10px;
      border: solid 1px #ff0000;
      padding: 2px 7px;
      border-radius: 10px;
      margin-top: 18px;
      background-color: #ff0000;
      color: #ffffff;
      position: absolute;
      top: -20px;
      left: 50%; }
    .nav-chat-noti {
      left: 55%; }

    cursor: pointer;
    color: #666;
    .icon {
      font-size: 18px;
      margin-top: -2px;
      margin-bottom: -2px;
      svg {
        font-size: 30px; } }
    .text {
      font-size: 9px; } }
  .item:hover {
    color: #000; } }
